import React, { useState, useEffect, memo } from "react"
import InformationWrapper from "../InformationWrapper"
import type {
  ChangeSubscriptionPageType,
  AllLayoutsType,
} from "../../types/ChangeSubscriptionPageType"

import {
  publicAPI,
  getListProducts,
  orderProductsByPrice,
  getProductResponse,
  handleErrorWithPrismic,
  USER_COUNTRY,
  setLocalStorage,
  isEmpty,
  getLocalStorage,
  Button,
  Spinner,
  getSessionStorage,
  CURRENT_COUNTRY,
} from "@lesmills/gatsby-theme-common"

import loadable from "@loadable/component"

const Subscriptions = loadable(() => import("./Subscriptions"), {
  fallback: <Spinner />,
})

const Notification = loadable(() => import("@lesmills/gatsby-theme-common"), {
  fallback: <Spinner />,
  resolveComponent: components => components.Notification,
})

type Props = {|
  title: string,
  prismicData: ChangeSubscriptionPageType,
  currentProductHandle: string,
  layoutData?: AllLayoutsType,
  isProcessing?: Boolean,
  handleSubmit?: () => void,
  isOnModal?: Boolean,
  selectedSubscriptionRef: { current: Object },
  handleDismissModal: () => void,
|}

const AvailableSubscriptions = ({
  title,
  prismicData = {},
  currentProductHandle,
  layoutData = {},
  isProcessing,
  handleSubmit,
  isOnModal,
  selectedSubscriptionRef,
  handleDismissModal,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState({})
  const [subscriptions, setSubscriptions] = useState([])
  const [error, setError] = useState(null)
  const {
    continue_button_label = {},
    save_button_label = {},
    back_button_label = {},
    lmod_lpbc_err = {},
  } = prismicData

  const isDisabledSubmitButton =
    isProcessing || Object.keys(selectedSubscription).length === 0

  const getSubscriptions = ({ data }) => {
    const res = getProductResponse(data)

    setLoading(false)
    setSubscriptions(orderProductsByPrice(res.products || []))
    if (isEmpty(getLocalStorage(USER_COUNTRY))) {
      setLocalStorage(USER_COUNTRY, getSessionStorage(CURRENT_COUNTRY))
    }
  }

  const handleError = err => {
    setLoading(false)

    // Handle error with prismic data
    handleErrorWithPrismic(err, lmod_lpbc_err.text, setError, prismicData)
  }

  const handleSelectSubscription = item => {
    setSelectedSubscription(item)
    selectedSubscriptionRef.current = item
  }

  useEffect(() => {
    setLoading(true)
    publicAPI(getListProducts, getSubscriptions, handleError)
  }, [])

  if (loading)
    return (
      <div className="relative mb-30 py-20">
        <Spinner />
      </div>
    )

  if (error) return <Notification message={error.message} type="error" />

  const availableSubscriptions = subscriptions.filter(
    item => item.product_handle !== currentProductHandle
  )

  return (
    <>
      <InformationWrapper title={title}>
        <Subscriptions
          selectedSubscription={selectedSubscription}
          layoutData={layoutData}
          prismicData={prismicData}
          handleSelectSubscription={handleSelectSubscription}
          subs={availableSubscriptions}
        />
      </InformationWrapper>
      {isOnModal ? (
        <div className="flex justify-between flex-wrap mt-30 mb-50">
          <Button
            handleOnClick={handleDismissModal}
            className="btn btn-layout w-full md:w-48/100 md:mb-0 mb-4"
            testId="cancel-change-subs-type-btn"
          >
            {back_button_label.text}
          </Button>
          <Button
            handleOnClick={handleSubmit}
            disabled={isDisabledSubmitButton}
            className="btn btn-primary uppercase w-full py-3 md:w-48/100"
            testId="save-change-subs-type-btn"
            submitting={isProcessing}
          >
            {save_button_label.text}
          </Button>
        </div>
      ) : (
        <Button
          className="w-full btn uppercase btn-primary py-3 mt-35 md:mt-60 mb-50"
          disabled={isDisabledSubmitButton}
          submitting={isProcessing}
          handleOnClick={handleSubmit}
          testId="continue-btn"
        >
          {continue_button_label.text}
        </Button>
      )}
    </>
  )
}

export default memo(AvailableSubscriptions, (prevProps, nextProps) => {
  return prevProps.isProcessing === nextProps.isProcessing
})
