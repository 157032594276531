import React, { useState, memo, useRef } from "react"
import isEqual from "lodash/isEqual"
import { RichText } from "prismic-reactjs"
import { navigate } from "gatsby"

import type {
  SubscriptionType,
  AllLayoutsType,
} from "@lesmills/gatsby-theme-common"
import type { ChangeSubscriptionPageType } from "../../types/ChangeSubscriptionPageType"

import {
  ROUTES,
  FrequentlyAskedQuestions,
  setLocalStorage,
  NEW_SUBSCRIPTION_KEY,
  convertCentsToEuro,
  Notification,
  getSubscriptionType,
  formatIntervalUnit,
  linkResolver,
  htmlSerializerUpdateStyle,
  CURRENCIES,
} from "@lesmills/gatsby-theme-common"
import InformationWrapper from "../InformationWrapper"
import AvailableSubscriptions from "../AvailableSubscriptions"

type Props = {|
  isOnModal?: boolean,
  handleDismissModal?: () => void | Promise<void>,
  currentSubscription: SubscriptionType,
  data: ChangeSubscriptionPageType,
  handleSubmitModal?: () => void | Promise<void>,
  lang: string,
  layoutData?: AllLayoutsType,
  isNotIncludedTax?: Boolean,
  plusTaxText: String,
|}

const ChangeSubscriptionType = ({
  currentSubscription = {},
  isOnModal,
  handleDismissModal,
  data,
  handleSubmitModal = () => {},
  lang,
  layoutData = {},
  isNotIncludedTax = false,
  plusTaxText = "",
}: Props) => {
  const selectedSubscriptionRef = useRef({})

  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState({})

  const { product, total_price_in_cents } = currentSubscription || {}

  const { name, product_price_point = {}, product_handle, currency } =
    product || {}
  const { body = [], subtitle = {}, payment_label = { text: "" } } = data || {}
  const [subscription = {}, faq = {}] = body
  const { items = [] } = subscription
  const { primary = { faq_title: {} } } = faq
  const questions = faq.items || []
  const [
    currentSubscriptionLabel = { subscription_section: {} },
    availableSubscriptionLabel = { subscription_section: {} },
  ] = items

  const handleSubmit = () => {
    setIsProcessing(true)

    setLocalStorage(NEW_SUBSCRIPTION_KEY, {
      ...selectedSubscriptionRef.current,
    })

    if (isOnModal) {
      handleSubmitModal(
        selectedSubscriptionRef.current,
        err => setError(err),
        handleDismissModal
      )
      return false
    }

    navigate(`${ROUTES(lang).CHANGE_SUBSCRIPTION}?step=summary`)
  }

  return (
    <>
      {Object.keys(error).length > 0 && (
        <Notification
          type="error"
          message={error.message}
          classNames={{ wrapper: "w-full" }}
        />
      )}
      <div
        data-cy="change-subscription-subtitle"
        data-testid="change-subscription-subtitle"
      >
        <RichText
          render={subtitle.raw}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            `font-base-light mt-25 mb-30 text-gray-800 leading-snug md:leading-7none md:text-2lg text-base${
              isOnModal ? " text-center" : ""
            }`
          )}
        />
      </div>
      <InformationWrapper
        title={currentSubscriptionLabel.subscription_section.text}
        classNames={{ wrapper: " mb-25 md:mb-44" }}
      >
        <p
          className="font-base-black text-base leading-snug text-gray-800 md:leading-7none md:text-2lg mt-25"
          data-cy="current-subscription-type"
          data-testid="current-subscription-type"
        >
          {name}
        </p>
        <div className="flex justify-between mt-4">
          <label className="font-base-light text-gray-800 md:leading-7none md:text-2lg text-3xs text-gray-800 leading-3normal">
            {payment_label.text.replace(
              "@interval",
              getSubscriptionType(product_price_point, layoutData)
            )}
          </label>
          {Object.keys(product_price_point).length > 0 && (
            <p
              className="font-base-light text-gray-800 md:leading-7none md:text-2lg text-3xs text-gray-800 leading-3normal"
              data-cy="current-subscription-price"
              data-testid="current-subscription-price"
            >
              {CURRENCIES[currency]}
              {convertCentsToEuro(total_price_in_cents, currency)}/
              {formatIntervalUnit(product_price_point, layoutData)}
              {/* https://lesmillsinternational.atlassian.net/browse/LA-1221
              Add "plus Tax" right after price in US and CA */}
              {isNotIncludedTax ? `(${plusTaxText})` : ""}
            </p>
          )}
        </div>
      </InformationWrapper>
      <AvailableSubscriptions
        title={availableSubscriptionLabel.subscription_section.text}
        prismicData={data}
        currentProductHandle={product_handle}
        layoutData={layoutData}
        isProcessing={isProcessing}
        handleSubmit={handleSubmit}
        isOnModal={isOnModal}
        selectedSubscriptionRef={selectedSubscriptionRef}
        handleDismissModal={handleDismissModal}
      />
      <InformationWrapper title={primary.faq_title.text}>
        <FrequentlyAskedQuestions
          questions={questions}
          classNames={{
            main: {
              wrapper: isOnModal ? " mb-30" : " mb-50",
              item: {
                wrapper: "mt-25 relative",
                title:
                  "font-base-medium md:text-base leading-snug text-2xs md:leading-7none text-gray-800",
                icon: " absolute top-0 right-0",
              },
            },
            answers:
              "text-gray-800 font-base-light md:text-base md:leading-7none text-2xs leading-snug mb-10",
            children: "mt-26",
          }}
          icon="plus-minus"
        />
      </InformationWrapper>
    </>
  )
}

export default memo(ChangeSubscriptionType, (prevProps, nextProps) => {
  return isEqual(prevProps.currentSubscription, nextProps.currentSubscription)
})
