// flow
import React, { memo } from "react"
import isEqual from "lodash/isEqual"
import type {
  SubscriptionType,
  AllLayoutsType,
} from "@lesmills/gatsby-theme-common"
import type { ChangeSubscriptionPageType } from "../../types/ChangeSubscriptionPageType"

import ChangeSubscriptionType from "../ChangeSubscription/ChangeSubscriptionType"
import AccountManagementModal from "../AccountManagementModal"

type Props = {|
  handleDismiss: () => void | Promise<void>,
  handleSubmitModal: () => void | Promise<void>,
  currentSubscription: SubscriptionType,
  availableSubscriptions: Array<SubscriptionType>,
  data: ChangeSubscriptionPageType,
  layoutData?: AllLayoutsType,
  isNotIncludedTax?: Boolean,
  plusTaxText: String,
|}

const ChangeSubscriptionTypeModal = ({
  handleDismiss,
  currentSubscription,
  availableSubscriptions,
  data,
  handleSubmitModal,
  layoutData = {},
  isNotIncludedTax,
  plusTaxText,
}: Props) => (
  <AccountManagementModal
    handleDismiss={handleDismiss}
    title={data.title.text}
    classNames={{ wrapper: "max-w-595" }}
    testId="change-subscription-type-modal"
  >
    <ChangeSubscriptionType
      data={data}
      isOnModal
      handleDismissModal={handleDismiss}
      currentSubscription={currentSubscription}
      availableSubscriptions={availableSubscriptions}
      handleSubmitModal={handleSubmitModal}
      isNotIncludedTax={isNotIncludedTax}
      plusTaxText={plusTaxText}
      layoutData={layoutData}
    />
  </AccountManagementModal>
)

export default memo(ChangeSubscriptionTypeModal, (prevProps, nextProps) => {
  return isEqual(prevProps.currentSubscription, nextProps.currentSubscription)
})
